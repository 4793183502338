import React from "react";
import Loadable from "@loadable/component";

import Layout from "../components/common/layout";
import SEO from "../components/common/seo";
const AllServices = Loadable(() =>
  import("../components/services/allServices")
);
const Hero = Loadable(() => import("../components/services/hero"));
const QuoteSection = Loadable(() =>
  import("../components/services/quoteSection")
);

const Services = () => (
  <Layout>
    <SEO
      title="Services"
      description="Clarity is focused on helping the Government redefine its relationship with technology by encouraging the use of DevSecOps and Agile methodologies, small-teams constructs, and process automation."
    />
    <Hero />
    <AllServices />
    <QuoteSection />
  </Layout>
);

export default Services;
